export default {
    projectConfig: {
        schoolRecruitment: {
            storeKey: 'scrStore',
            PJSource: 6,
            PJID: 'SCR',
            domainNames: {
                idc: [{
                    en: 'ailajob.com',
                    zh: 'ailajob.com'
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        ymSaleTool: {
            storeKey: 'ystStore',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://tool.yundida.com/'],
                test: ['https://tool.yundida.com/'],
                dev: ['https://api-dev.aila.site/']
            }
        },
        siffa: {
            storeKey: 'sifStore',
            PJSource: 7,
            PJID: 'SIF',
            domainNames: {
                idc: [
                    {
                        en: 'en.siffa.org',
                        zh: 'www.siffa.org'
                    },
                    {
                        en: 'en.siffa.org',
                        zh: 'siffa.org'
                    },
                    {
                        en: '10.0.0.20:8081',
                        zh: 'localhost:8081'
                    },
                ],
                test: [
                    {
                        en: 'testen-siffa.aila.site',
                        zh: 'test-siffa.aila.site'
                    },
                    {
                        en: '10.0.0.20:8083',
                        zh: 'localhost:8083'
                    },
                ],
                dev: [{
                    en: '10.0.0.22:8083',
                    zh: 'localhost:8081'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        expoMeeting: {
            storeKey: 'expStore',
            PJSource: 10,
            PJID: 'EXP',
            domainNames: {
                idc: [{
                    en: 'en.expo56.com',
                    zh: 'expo56.com'
                }, {
                    en: 'en.expo56.com',
                    zh: 'www.expo56.com'
                }, {
                    en: 'localhost:8082',
                    zh: 'localhost:8082'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-expo.aila.site'
                }, {
                    en: 'localhost:8081',
                    zh: 'localhost:8080'
                }],
                dev: [{
                    en: 'localhost:8083',
                    zh: 'localhost:8081'
                },
                {
                    en: 'localhost:8081',
                    zh: '192.168.1.2:8080'
                }
                ]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        fudaMeeting: {
            storeKey: 'fdaStore',
            PJSource: 11,
            PJID: 'FDA',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-expo.aila.site'
                }],
                dev: [{
                    en: '192.168.1.25:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        tradeFederation: {
            storeKey: 'trfStore',
            PJSource: 12,
            PJID: 'TRF',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-nbdlt.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        lySociety: {
            storeKey: 'lysStore',
            PJSource: 16,
            PJID: 'LYS',
            domainNames: {
                idc: [{
                    en: 'nita.aila.site/',
                    zh: 'nita.aila.site/'
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        scbSociety: {
            storeKey: 'scbStore',
            PJSource: 13,
            PJID: 'SCB',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'https://test-scba.aila.site/'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        mpaSociety: {
            storeKey: 'mpaStore',
            PJSource: 18,
            PJID: 'MPA',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        ailaWRT: {
            storeKey: 'wrtStore',
            PJSource: 0,
            PJID: 'WRT',
            domainNames: {
                idc: [{
                    en: 'wrten.aila.site',
                    zh: 'wrt.aila.site'
                }],
                test: [{
                    en: 'testen-wrt.aila.site',
                    zh: 'test-wrt.aila.site'
                }],
                dev: [{
                    en: '192.168.1.30:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev-mini.aila.site/service/']
            }
        },
        overseasMeeting: {
            storeKey: 'osmStore',
            PJSource: 17,
            PJID: 'OSM',
            domainNames: {
                idc: [{
                    en: 'wfa.aila.site',
                    zh: ''
                }, {
                    en: 'overseas.ailaworld.com',
                    zh: ''
                }],
                test: [{
                    en: 'test-wfa.aila.site',
                    zh: ''
                }, {
                    en: 'localhost:8082',
                    zh: ''
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: ''
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev-mini.aila.site/service/']
            }
        },
        ymPcClient: {
            storeKey: 'ympStore',
            // PJSource: 18,
            PJID: 'YMP',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://tool.yundida.com/ymfr/'],
                test: ['https://api-test.aila.site/ymfr/'],
                dev: ['https://api-dev.aila.site/ymfr/']
            }
        },
        qlcSociety: {
            storeKey: 'qlcStore',
            PJSource: 14,
            PJID: 'QLC',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: '192.168.1.10:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        airExpoMeeting: {
            storeKey: 'axpStore',
            PJSource: 21,
            PJID: 'AXP',
            domainNames: {
                idc: [{
                    en: 'en.shippingexpo.net',
                    zh: 'www.shippingexpo.net'
                }, {
                    en: 'en.shippingexpo.net',
                    zh: 'shippingexpo.net'
                }, {
                    en: 'en.axp.aila.site',
                    zh: 'axp.aila.site'
                }],
                test: [{
                    en: 'testen-axp.aila.site',//
                    zh: 'test-axp.aila.site'
                }],
                dev: [{
                    en: '10.0.0.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        gbkSociety: {
            storeKey: 'gbkStore',
            PJSource: 20,
            PJID: 'GBK',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        hebeiSociety: {
            storeKey: 'hbsStore',
            PJSource: 26,
            PJID: 'HBS',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        xiffaSociety: {
            storeKey: 'xifStore',
            PJSource: 27,
            PJID: 'XIF',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        dlSociety: {
            storeKey: 'dlsStore',
            PJSource: 29,
            PJID: 'DLS',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        tiffaSociety: {
            storeKey: 'tifStore',
            PJSource: 30,
            PJID: 'TIF',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        tcbaSociety: {
            storeKey: 'tcbStore',
            PJSource: 31,
            PJID: 'TCB',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        hphSociety: {
            storeKey: 'hphStore',
            PJSource: 33,
            PJID: 'HPH',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        henanSociety: {
            storeKey: 'hnsStore',
            PJSource: 34,
            PJID: 'HNS',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        dgaJob: {
            storeKey: 'dgbStore',
            PJSource: 35,
            PJID: 'DGB',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        jzSociety: {
            storeKey: 'jzsStore',
            PJSource: 36,
            PJID: 'JZS',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        nbkjMeeting: {
            storeKey: 'nbkStore',
            PJSource: 37,
            PJID: 'NBK',
            domainNames: {
                idc: [{
                    en: 'ncle.ailaworld.com',
                    zh: ''
                }],
                test: [{
                    en: 'test-nbk.aila.site',
                    zh: ''
                },{
                    en: 'localhost:8080',
                    zh: '192.168.1.14:8080'
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: '192.168.1.14:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        bfMeeting: {
            storeKey: 'bfmStore',
            PJSource: 40,
            PJID: 'BFM',
            domainNames: {
                idc: [{
                    en: 'ncle.ailaworld.com',
                    zh: ''
                }],
                test: [{
                    en: 'test-nbk.aila.site',
                    zh: ''
                },{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }],
                dev: [{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        hymSociety: {
            storeKey: 'hymStore',
            PJSource: 41,
            PJID: 'HYM',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                },{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }],
                dev: [{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        dlcSociety: {
            storeKey: 'dlcStore',
            PJSource: 44,
            PJID: 'DLC',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                },{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }],
                dev: [{
                    en: '192.168.31.150:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
    }
}
