<template>
  <div class="siffaPageBottom">
    <div class="content_area">
      <!-- <p class="friendLink">友情链接：</p>
      <ul class="link_box">
        <li
          v-for="(item, i) in Links_arr"
          :key="i"
          @click="toHref(item)"
          class="clearfix"
          :title="item.lable"
        >
            <al-image :src="item.url" fit="scale-down" />
        </li>
      </ul> -->
      <div class="contact_follow">
        <div class="contact">
          <h2>联系我们</h2>
          <ul>
            <li>任莉</li>
            <li v-for="(item, i) in contact_arr" :key="i">
              {{ item.lable }}{{ item.content }}
            </li>
          </ul>
        </div>
        <div class="follow">
          <h2>扫码，关注微信公众号</h2>
          <ul>
            <li v-for="(item, i) in QRcode_arr" :key="i">
              <el-image :src="item.url" fit="contain"></el-image>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      <bottomContent></bottomContent>
      Copyright@大连市物流协会 版权所有
      <a href="https://ailaworld.com.cn/">AiLaworld.com</a>
    </div>
  </div>
</template>

<script>
import bottomContent from "~dls/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      Links_arr: [
        {
          lable: "宁波国际物流发展股份有限公司",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/friendLink1.png",
          href: "http://www.nbgjwl.com/indexpc.do",
        },
        {
          lable: "AiLa",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/friendLink2.png",
          href: "http://www.ailaworld.com",
        },
        {
          lable: "中华人民共和国宁波海关",
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/friendLink3.png",
          href: "http://ningbo.customs.gov.cn/",
        },
      ],
      contact_arr: [
        {
          lable: "电话：",
          content: "82798279，82622158",
        },
        // {
        //   lable: "邮箱：",
        //   content: "nbwmlhh@vip.163.com",
        // },
        {
          lable: "地址：",
          content: "大连市中山区人民路15号，国际金融大厦16楼D",
        },


      ],
      QRcode_arr: [
        {
          name: "微信",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dls/idCode.jpg",
        },
        // {
        //   name: "二维码",
        //   url:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/idCode.jpg",
        // },
      ],
    };
  },
  methods: {
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style  scoped lang="less">
.siffaPageBottom {
  width: 100%;
  background: #192F59;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #fff;
  }
  .link_box {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 24px;
      width: 150px;
      height: 50px;
    }
  }
  .contact_follow {
    padding-top: 42px;
    display: flex;
    h2 {
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #DCDFE6;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 1070px;
      height: 220px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #DCDFE6;
          line-height: 30px;
        }
      }
    }
    .follow {
      min-width: 200px;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #ececec;
    color: #fff;
    padding-bottom: 10px;
    font-size: 12px;
    a {
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
