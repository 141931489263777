import consolePreview from './consolePrview'
import expoMeeting from './expoMeeting'
import fudaMeeting from './fudaMeeting'
import tradeFederation from './tradeFederation'
import siffa from './siffa'
import lySociety from './lySociety'
import scbSociety from './scbSociety'
import overseasMeeting from './overseasMeeting'
import ymPcClient from './ymPcClient'
import qlcSociety from './qlcSociety'
import mpaSociety from './mpaSociety'
import gbkSociety from './gbkSociety'
import airExpoMeeting from './airExpoMeeting'
import hebeiSociety from './hebeiSociety'
import xiffaSociety from './xiffaSociety'
import dlSociety from './dlSociety'
import tiffaSociety from './tiffaSociety'
import tcbaSociety from './tcbaSociety'
import hphSociety from './hphSociety'
import dgaJob from './dgaJob'
import henanSociety from './henanSociety'
import nbkjMeeting  from './nbkjMeeting'
import bfMeeting from "./bfMeeting";
import hymSociety from "./hymSociety";
import dlcSociety from "./dlcSociety";
import jzSociety from './jzSociety'

export default {
    consolePreview,
    expoMeeting,
    fudaMeeting,
    tradeFederation,
    siffa,
    lySociety,
    scbSociety,
    overseasMeeting,
    ymPcClient,
    qlcSociety,
    mpaSociety,
    gbkSociety,
    airExpoMeeting,
    hebeiSociety,
    xiffaSociety,
    dlSociety,
    tiffaSociety,
    tcbaSociety,
    hphSociety,
    dgaJob,
    henanSociety,
    nbkjMeeting,
    bfMeeting,
    hymSociety,
    dlcSociety,
    jzSociety,
}
