import { commonEnumFormat } from '~/basePlugins/filters'

import { creditClass } from '~dls/utils/enumValue'                 //信用等级
export const creditClassFormat = commonEnumFormat(creditClass)

import { unitType } from '~dls/utils/enumValue'                 //单位类型
export const unitTypeFormat = commonEnumFormat(unitType)

import { getCityNameById } from '@/baseUtils/AddressConfig'
export const getCityNameByCityId = getCityNameById//根据城市id获取城市名

import { levelRequire } from '~scr/utils/enumValue'                 //学历要求
export const levelRequireFormat = commonEnumFormat(levelRequire)

import { yearList } from '~dls/utils/enumValue'                 //单位类型
export const yearListFormat = commonEnumFormat(yearList)
import { staffsizeList } from '~dls/utils/enumValue'                 //信用等级
export const staffsizeFormat = commonEnumFormat(staffsizeList);

import {setYears} from '~dls/utils/enumValue' //物贸联合会成立年限
export const trfYearFormat=commonEnumFormat(setYears)
//工作经验
import { experience } from '~scr/utils/enumValue'
export const experienceFormat=commonEnumFormat(experience)
import { weeksList } from '~sif/utils/enumValue'
export const weeksFormat = function (input) {
    let str = '-'
    if (input || input == 0) {
        str = weeksList.filter(item => {return  input == item.value})[0].label
    }
    return str
}