import Vue from 'vue'
import scrStore from '~scr/store'
import scrI18n from '~scr/locale'
import ystStore from '~yst/store'
import ystI18n from '~yst/locale'
import sifI18n from '~sif/locale'
import sifStore from '~sif/store'
import expI18n from '~exp/locale'
import expStore from '~exp/store'
import fdaI18n from '~fda/locale'
import fdaStore from '~fda/store'
import wrtI18n from '~wrt/locale'
import wrtStore from '~wrt/store'
import trfI18n from '~trf/locale'
import trfStore from '~trf/store'
import lysI18n from '~lys/locale'
import lysStore from '~lys/store'
import scbI18n from '~scb/locale'
import mpaI18n from '~mpa/locale'
import scbStore from '~scb/store'
import mpaStore from '~mpa/store'
import osmI18n from '~osm/locale'
import osmStore from '~osm/store'
import ympI18n from '~ymp/locale'
import ympStore from '~ymp/store'
import qlcI18n from '~qlc/locale'
import qlcStore from '~qlc/store'
import axpI18n from '~axp/locale'
import axpStore from '~axp/store'
import gbkI18n from '~gbk/locale'
import gbkStore from '~gbk/store'
import hbsI18n from '~hbs/locale'
import hbsStore from '~hbs/store'
import xifI18n from '~xif/locale'
import xifStore from '~xif/store'
import dlsI18n from '~dls/locale'
import dlsStore from '~dls/store'
import tifI18n from '~tif/locale'
import tifStore from '~tif/store'
import tcbI18n from '~tcb/locale'
import tcbStore from '~tcb/store'
import hphI18n from '~hph/locale'
import hphStore from '~hph/store'
import hnsI18n from '~hns/locale'
import hnsStore from '~hns/store'
import dgbI18n from '~dgb/locale'
import dgbStore from '~dgb/store'
import jzsI18n from '~jzs/locale'
import jzsStore from '~jzs/store'
import nbkI18n from '~nbk/locale'
import nbkStore from '~nbk/store'
import bfmI18n from '~bfm/locale'
import bfmStore from '~bfm/store'
import hymI18n from '~hym/locale'
import hymStore from '~hym/store'
import dlcI18n from '~dlc/locale'
import dlcStore from '~dlc/store'
import baseConfig from '~/baseConfig'
import i18nConfig from '~/baseConfig/i18n'
import { confirmProjectLocale } from '~/baseUtils'
Vue.use(VueI18n)

const AllI18ns = {
    scrI18n,
    ystI18n,
    sifI18n,
    expI18n,
    wrtI18n,
    fdaI18n,
    trfI18n,
    lysI18n,
    scbI18n,
    osmI18n,
    ympI18n,
    qlcI18n,
    mpaI18n,
    axpI18n,
    gbkI18n,
    hbsI18n,
    xifI18n,
    dlsI18n,
    tifI18n,
    tcbI18n,
    hphI18n,
    hnsI18n,
    dgbI18n,
    jzsI18n,
    nbkI18n,
    bfmI18n,
    hymI18n,
    dlcI18n,
}

const allStore = {
    scrStore,
    ystStore,
    sifStore,
    expStore,
    wrtStore,
    fdaStore,
    trfStore,
    lysStore,
    scbStore,
    osmStore,
    ympStore,
    qlcStore,
    mpaStore,
    axpStore,
    gbkStore,
    hbsStore,
    xifStore,
    dlsStore,
    tifStore,
    tcbStore,
    hphStore,
    hnsStore,
    dgbStore,
    jzsStore,
    nbkStore,
    bfmStore,
    hymStore,
    dlcStore,
}

const CurrentProjectConfig = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME];
const CurrentI18nConfig = i18nConfig[process.env.VUE_APP_PROJECT_NAME]
export const getI18n = async function () {
    let zhLocales = {};
    let enLocales = {};
    const Locale = CurrentI18nConfig.locale ? CurrentI18nConfig.locale : confirmProjectLocale()
    allStore[CurrentProjectConfig.storeKey].commit('baseStore/SET_LANG', Locale)
    zhLocales = Object.assign({}, AllI18ns[CurrentI18nConfig.i18nKey].zhLocales)
    enLocales = Object.assign({}, AllI18ns[CurrentI18nConfig.i18nKey].enLocales)
    if (process.env.NODE_ENV == 'development') {
        let _zhLocales = {};
        let _enLocales = {};
        let _result = await allStore[CurrentProjectConfig.storeKey].dispatch('baseStore/getAllI18nWords', { belong: CurrentI18nConfig.i18nBelong, is_idc: 2, no_page: 1 })
        _result.data.forEach(item => {
            _zhLocales[item.key] = item.label_zh
            _enLocales[item.key] = item.label_en
        })
        zhLocales = Object.assign(zhLocales, _zhLocales)
        enLocales = Object.assign(enLocales, _enLocales)
    }
    return new VueI18n({
        locale: Locale,
        fallbackLocale: 'zh',           // 定义默认语言为中文 
        silentTranslationWarn: true,
        messages: {
            'zh': zhLocales,
            'en': enLocales
        }
    });
}
